import { Box, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import {
  Address,
  Order,
  Store,
} from "~/interfaces/entities/autopilot/AccountWithOrders"
import { getCountryName } from "~/services/Utils"

interface Props {
  order: Order
}

const OrderTableFulfillment: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation()
  const fulfillmentLabel =
    order.fulfillmentType === "pickup"
      ? t("components.account.orders.list.OrderTableFulfillment.StorePickUp")
      : t("components.account.orders.list.OrderTableFulfillment.Delivery")

  return (
    <Box>
      <Text fontWeight="bold" mb={2}>
        {fulfillmentLabel}
      </Text>
      <AddressDetails order={order} />
    </Box>
  )
}

const AddressDetails = ({ order }: { order: Order }) => {
  const isPickup = order.fulfillmentType === "pickup"

  return isPickup ? (
    <StoreAddress store={order.store[0]} />
  ) : (
    <UserAddress address={order.address[0]} />
  )
}

const UserAddress = ({ address }: { address: Address }) => {
  return (
    <VStack alignItems="flex-start" spacing={1}>
      <Text>{address.name}</Text>
      <Text>{address.addressLine1}</Text>
      {address.addressLine2 && <Text>{address.addressLine2}</Text>}
      <Text>
        {address.postCode} {address.city}
      </Text>
      <Text>{getCountryName(address.country)}</Text>
    </VStack>
  )
}

const StoreAddress = ({ store }: { store: Store }) => {
  return (
    <VStack alignItems="flex-start" spacing={1}>
      <Text>{store.name}</Text>
      <Text>{store.storeAddress1}</Text>
      {store.storeAddress2 && <Text>{store.storeAddress2}</Text>}
      <Text>
        {store.storePostCode} {store.storeCity}
      </Text>
      <Text>{getCountryName(store.storeCountry)}</Text>
    </VStack>
  )
}

export default OrderTableFulfillment
