import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import format from "date-fns/format"
import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { usePagination } from "~/hooks/usePagination"
import { Order } from "~/interfaces/entities/autopilot/AccountWithOrders"
import { formatCurrency } from "~/services/Utils"
import RoundedPagination from "../../../RoundedPagination"
import OrderPreviewImages from "../OrderPreviewImages"
import OrderStatus from "../OrderStatus"
import { orderItemsPerPage } from "./OrderTable"
import OrderTableEmptyResult from "./OrderTableEmptyResult"
import OrderTableFulfillment from "./OrderTableFulfillment"

interface Props {
  orders: Order[]
}

const OrderTableMobile: React.FC<Props> = ({ orders }) => {
  const { t } = useTranslation()
  const { page, currentPage, gotoPage, nextPage, previousPage, totalPages } =
    usePagination<Order>(orders, {
      itemsPerPages: orderItemsPerPage,
      orderBy: {
        id: "desc",
      },
    })

  const isTablet = useBreakpointValue({
    base: false,
    sm: true,
  })

  if (!page) {
    return <OrderTableEmptyResult />
  }

  return (
    <Box>
      {page?.map(order => {
        const orderLink = `/account/orders/${order.id}`
        const lineItemsPreviewed = order.lineItems.slice(0, 3)

        return (
          <Box
            key={order.id}
            boxShadow="base"
            borderRadius={5}
            overflow="hidden"
            mb={5}
          >
            <Box
              fontWeight="bold"
              width="100%"
              display="flex"
              bg="gray.100"
              color="gray.600"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                p={4}
              >
                <Flex alignItems="center" mr={2}>
                  <Text fontWeight="bold">
                    {t("components.account.orders.list.OrderTable.Order")}
                  </Text>
                  <Button
                    as={Link}
                    variant="link"
                    colorScheme="primary"
                    ml={1}
                    to={orderLink}
                  >
                    #{order.id}
                  </Button>
                </Flex>

                <Button
                  id={`order-${order.id}-mobile`}
                  as={Link}
                  variant="outline"
                  colorScheme="primary"
                  size={isTablet ? "sm" : "xs"}
                  to={orderLink}
                >
                  {t("components.account.orders.list.OrderTable.ViewDetails")}
                </Button>
              </Flex>
            </Box>

            <Row title={t("components.account.orders.list.OrderTable.Preview")}>
              <Box
                width={{ base: "200px", lg: "150px" }}
                py={lineItemsPreviewed.length > 1 ? 5 : 0}
              >
                <OrderPreviewImages
                  lineItems={lineItemsPreviewed}
                  maxWidth={{ base: "200px", lg: "150px" }}
                  maxHeight={{ base: "200px", lg: "120px" }}
                />
              </Box>

              <OrderStatus status={order.displayStatus} />
            </Row>

            <Row
              title={t("components.account.orders.list.OrderTable.OrderPlaced")}
            >
              <Box>
                <Text m={0}>{format(new Date(order.createdAt), "PP")}</Text>
                <Text m={0}>{format(new Date(order.createdAt), "pp")}</Text>
              </Box>
            </Row>

            <Row
              title={t("components.account.orders.list.OrderTable.Fulfillment")}
            >
              <OrderTableFulfillment order={order} />
            </Row>

            <Row
              title={t("components.account.orders.list.OrderTable.Total")}
              align="end"
              neverBreak={true}
            >
              <Text>{formatCurrency(order.currency, Number(order.total))}</Text>
            </Row>
          </Box>
        )
      })}

      <Flex justifyContent="center" w="100%">
        <RoundedPagination
          id="orders-mobile"
          currentPage={currentPage}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
          totalPages={totalPages}
        />
      </Flex>
    </Box>
  )
}

export default OrderTableMobile

interface RowProps {
  title: string
  children: any
  align?: "start" | "end"
  neverBreak?: boolean
}

const Row: React.FC<RowProps> = ({
  title,
  children,
  align = "start",
  neverBreak = false,
}) => {
  return (
    <Flex
      alignItems="stretch"
      borderBottomWidth={1}
      borderStyle="solid"
      borderColor="gray.100"
      flexDirection={{ base: neverBreak ? "row" : "column", sm: "row" }}
      p={4}
    >
      <Box width={{ base: neverBreak ? "40%" : "100%", sm: "40%" }}>
        <Text fontWeight="bold" color="gray.600">
          {title}
        </Text>
      </Box>

      <Box width={{ base: neverBreak ? "60%" : "100%", sm: "60%" }}>
        <Stack
          direction={{
            base: "column",
            xl: "row",
          }}
          alignItems={`flex-${align}`}
          spacing={{ base: 3, xl: 6 }}
        >
          {children}
        </Stack>
      </Box>
    </Flex>
  )
}
