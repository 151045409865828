import { useQuery } from "react-query"
import { useAuth } from "./useAuth"
import { usePrinticularApi } from "./usePrinticularApi"

export const useAccountWithOrders = () => {
  const {
    state: { id, isAuthenticated },
  } = useAuth()

  const api = usePrinticularApi()

  const {
    isLoading,
    isIdle,
    isError,
    data: account,
  } = useQuery(["orders", id], () => api.getUserAccountOrders(), {
    enabled: !!isAuthenticated,
  })

  return { isLoading: isLoading || isIdle, isError, account }
}
