import {
  Box,
  Button,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { useApplicationState } from "~/hooks/useApplicationState"
import { usePagination } from "~/hooks/usePagination"
import { Order } from "~/interfaces/entities/autopilot/AccountWithOrders"
import { formatCurrency, formatDate } from "~/services/Utils"
import RoundedPagination from "../../../RoundedPagination"
import { OrderHeader } from "../OrderHeader"
import OrderPreviewImages from "../OrderPreviewImages"
import OrderStatus from "../OrderStatus"
import OrderTableEmptyResult from "./OrderTableEmptyResult"
import OrderTableFulfillment from "./OrderTableFulfillment"

export const orderItemsPerPage = 5

interface Props {
  orders: Order[]
}

const OrderTable: React.FC<Props> = ({ orders }) => {
  const { t } = useTranslation()
  const { page, currentPage, gotoPage, nextPage, previousPage, totalPages } =
    usePagination<Order>(orders, {
      itemsPerPages: orderItemsPerPage,
      orderBy: {
        id: "desc",
      },
    })

  const { language } = useApplicationState()

  const isLargeDesktop = useBreakpointValue({
    base: false,
    xl: true,
  })

  if (!page) {
    return <OrderTableEmptyResult />
  }

  return (
    <Stack spacing={{ base: 5, lg: 8 }} alignItems="center">
      <Table variant="simple" size="md">
        <Thead bg="gray.100" boxShadow={{ base: "base", lg: "none" }}>
          <Tr whiteSpace="nowrap">
            <Th>{t("components.account.orders.list.OrderTable.Order")}</Th>
            <Th>
              {t("components.account.orders.list.OrderTable.OrderPlaced")}
            </Th>
            <Th>
              {t("components.account.orders.list.OrderTable.Fulfillment")}
            </Th>
            <Th isNumeric>
              {t("components.account.orders.list.OrderTable.Total")}
            </Th>
            {isLargeDesktop && <Th></Th>}
          </Tr>
        </Thead>

        <Tbody>
          {page?.map(order => {
            const lineItemsPreviewed = order.lineItems.slice(0, 3)
            const linkToOrderPage = (
              <Button
                id={`order-${order.id}`}
                as={Link}
                variant="outline"
                colorScheme="primary"
                size="sm"
                to={`/account/orders/${order.id}`}
              >
                {t("components.account.orders.list.OrderTable.ViewDetails")}
              </Button>
            )
            return (
              <Tr
                key={order.id}
                boxShadow={{ base: "base", lg: "none" }}
                mb={{ base: 5, lg: 0 }}
                display={{ base: "block", lg: "table-row" }}
                borderRadius={4}
                overflow="hidden"
                verticalAlign="top"
              >
                <Td
                  title={t("components.account.orders.list.OrderTable.Preview")}
                  pr={!isLargeDesktop ? 0 : 6}
                >
                  <Box mx="auto">
                    <Stack
                      direction={{
                        base: "column",
                        xl: "row",
                      }}
                      alignItems="flex-start"
                      spacing={{ base: 3, xl: 6 }}
                    >
                      <Box
                        width={{ base: "200px", lg: "150px" }}
                        py={lineItemsPreviewed.length > 1 ? 5 : 0}
                      >
                        <OrderPreviewImages
                          lineItems={lineItemsPreviewed}
                          maxHeight={{ base: "200px", lg: "120px" }}
                          maxWidth="100%"
                        />
                      </Box>

                      <Box>
                        <Box mb={2}>
                          {Boolean(order.displayOrderIds) && (
                            <OrderHeader order={order!} />
                          )}
                        </Box>
                        <OrderStatus status={order.displayStatus} />
                      </Box>

                      {!isLargeDesktop && linkToOrderPage}
                    </Stack>
                  </Box>
                </Td>

                <Td
                  title={t(
                    "components.account.orders.list.OrderTable.OrderPlaced"
                  )}
                >
                  <Text>
                    {formatDate(new Date(order.createdAt), "PP", language)}
                  </Text>
                  <Text>
                    {formatDate(new Date(order.createdAt), "pp", language)}
                  </Text>
                </Td>

                <Td
                  title={t(
                    "components.account.orders.list.OrderTable.Fulfillment"
                  )}
                >
                  <OrderTableFulfillment order={order} />
                </Td>

                <Td
                  title={t("components.account.orders.list.OrderTable.Total")}
                  isNumeric
                >
                  <Text>
                    {formatCurrency(order.currency, Number(order.total))}
                  </Text>
                </Td>

                {isLargeDesktop && (
                  <Td border={0} pr={0}>
                    {linkToOrderPage}
                  </Td>
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>

      <RoundedPagination
        id="orders"
        currentPage={currentPage}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        totalPages={totalPages}
      />
    </Stack>
  )
}

export default OrderTable
