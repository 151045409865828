import { Box, useBreakpointValue } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "react-i18next"
import OrderTable from "~/components/account/orders/list/OrderTable"
import OrderTableMobile from "~/components/account/orders/list/OrderTableMobile"
import OrderTableSkeleton from "~/components/account/orders/list/OrderTableSkeleton"
import { useAccountWithOrders } from "~/hooks/useAccountWithOrders"
import AccountLayout from "~/templates/layout/account/AccountLayout"

interface Props {}

const Orders: React.FC<Props> = () => {
  const { account, isLoading } = useAccountWithOrders()
  const { t } = useTranslation()
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <AccountLayout
      title={t("pages.account.orders.index.PreviousOrders")}
      description={t("pages.account.orders.index.Description")}
      isLoading={isLoading}
      fullWidth={true}
    >
      {/* @TODO: add following text to description once reorder feature ready: You can reorder previous orders here. */}
      <Box mt={5}>
        {isLoading && <OrderTableSkeleton />}
        {!isLoading && (
          <>
            {isDesktop ? (
              <OrderTable orders={account!.orders} />
            ) : (
              <OrderTableMobile orders={account!.orders} />
            )}
          </>
        )}
      </Box>
    </AccountLayout>
  )
}

export default Orders
