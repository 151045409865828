import { Button, Flex, HStack, Text } from "@chakra-ui/react"
import { clamp, range } from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"

const pagePadding = 1

interface Props {
  currentPage: number
  nextPage: () => void
  previousPage: () => void
  gotoPage: (n: number) => void
  totalPages: number
  id?: string
}

const RoundedPagination: React.FC<Props> = ({
  currentPage,
  nextPage,
  previousPage,
  gotoPage,
  totalPages,
  id,
}) => {
  const { t } = useTranslation()
  const startVisibility = clamp(currentPage - pagePadding, 0, totalPages)
  const endVisibility = clamp(currentPage + pagePadding + 1, 0, totalPages)

  const showStartHyphens = currentPage > pagePadding
  const showEndHyphens = totalPages - 1 - currentPage > pagePadding

  const rangeToShow = range(startVisibility, endVisibility)

  return (
    <HStack>
      <Button
        id={id ? `${id}-page-previous` : undefined}
        onClick={previousPage}
        variant="ghost"
        colorScheme="primary"
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        isDisabled={currentPage === 0}
      >
        <Text>{t("components.RoundedPagination.Previous")}</Text>
      </Button>
      {showStartHyphens && <Text color="gray.400">...</Text>}
      {rangeToShow.map(index => {
        return (
          <Flex key={index}>
            <Button
              id={id ? `${id}-page-${index + 1}` : undefined}
              onClick={() => gotoPage(index)}
              height="40px"
              width="40px"
              p={0}
              fontSize="0.8em"
              rounded="full"
              variant="ghost"
              colorScheme="primary"
              isActive={index === currentPage}
              _active={{
                borderColor: "primary.500",
                borderWidth: 2,
                bg: "primary.50",
                boxShadow: "md",
              }}
            >
              <Text>{index + 1}</Text>
            </Button>
          </Flex>
        )
      })}
      {showEndHyphens && <Text color="gray.400">...</Text>}
      <Button
        id={id ? `${id}-page-next` : undefined}
        onClick={nextPage}
        variant="ghost"
        colorScheme="primary"
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        isDisabled={currentPage + 1 === totalPages}
      >
        <Text>{t("components.RoundedPagination.Next")}</Text>
      </Button>
    </HStack>
  )
}

export default RoundedPagination
