import {
  Box,
  HStack,
  Skeleton,
  SkeletonText,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react"
import { range } from "lodash"
import React from "react"

interface Props {}

const OrderTableSkeleton: React.FC<Props> = () => {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })

  const isTablet = useBreakpointValue({
    base: false,
    md: true,
    lg: false,
  })

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  return (
    <>
      {isDesktop && <DesktopSkeleton />}
      {isTablet && <TabletSkeleton />}
      {isMobile && <MobileSkeleton />}
    </>
  )
}

export default OrderTableSkeleton

const DesktopSkeleton = () => {
  return (
    <Stack>
      <Skeleton height="40px" startColor="gray.50" endColor="gray.100" />
      {range(3).map(index => {
        return (
          <HStack key={index} alignItems="flex-start">
            <Skeleton
              width={150}
              height={150}
              startColor="gray.50"
              endColor="gray.100"
            />
            <SkeletonText
              flex={1}
              startColor="gray.50"
              endColor="gray.100"
              noOfLines={5}
            />
          </HStack>
        )
      })}
    </Stack>
  )
}

const TabletSkeleton = () => {
  return (
    <>
      {range(3).map(index => {
        return (
          <Box key={index}>
            <Skeleton height="40px" startColor="gray.50" endColor="gray.100" />
            <HStack alignItems="flex-start" width="100%" py={6}>
              <SkeletonText
                startColor="gray.50"
                endColor="gray.100"
                noOfLines={5}
                flex={1}
              />
              <Box flex={1}>
                <Skeleton
                  height={150}
                  startColor="gray.50"
                  endColor="gray.100"
                  mb={2}
                  width="100%"
                />
                <SkeletonText
                  startColor="gray.50"
                  endColor="gray.100"
                  width="100%"
                />
              </Box>
            </HStack>
          </Box>
        )
      })}
    </>
  )
}

const MobileSkeleton = () => {
  return (
    <>
      {range(3).map(index => {
        return (
          <Box mb={5} key={index}>
            <Skeleton height="40px" startColor="gray.50" endColor="gray.100" />
            <Stack alignItems="flex-start" width="100%" py={3}>
              <Skeleton
                height={150}
                startColor="gray.50"
                endColor="gray.100"
                mb={2}
                width="100%"
              />
              <SkeletonText
                startColor="gray.50"
                endColor="gray.100"
                width="100%"
              />
            </Stack>
          </Box>
        )
      })}
    </>
  )
}
