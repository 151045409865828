import { chunk, clamp, orderBy as sortBy } from "lodash"
import { useCallback, useMemo, useState } from "react"

interface Options {
  itemsPerPages: number
  initialPage?: number
  orderBy?: {
    [key: string]: "asc" | "desc"
  }
}

export const usePagination = <T>(
  data: T[],
  { itemsPerPages, initialPage = 0, orderBy = {} }: Options
) => {
  const [currentPage, setCurrentPage] = useState(initialPage)

  const pages = useMemo(() => {
    const ordered = sortBy(data, Object.keys(orderBy), Object.values(orderBy))
    return chunk(ordered, itemsPerPages)
  }, [data, itemsPerPages, orderBy])

  const totalPages = pages.length

  const gotoPage = useCallback(
    (n: number) => {
      setCurrentPage(clamp(n, 0, pages.length - 1))
    },
    [pages.length]
  )

  const nextPage = useCallback(() => {
    setCurrentPage(n => clamp(n + 1, 0, pages.length - 1))
  }, [pages.length])

  const previousPage = useCallback(() => {
    setCurrentPage(n => clamp(n - 1, 0, pages.length - 1))
  }, [pages.length])

  const page = pages[currentPage]

  return {
    page,
    pages,
    currentPage,
    gotoPage,
    nextPage,
    previousPage,
    totalPages,
  }
}
