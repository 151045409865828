import { Alert, AlertIcon } from "@chakra-ui/alert"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props {}

const OrderTableEmptyResult: React.FC<Props> = () => {
  const { t } = useTranslation()
  return (
    <Alert status="info" borderRadius={5} color="blue.700" fontStyle="italic">
      <AlertIcon />
      {t("components.account.orders.list.OrderTableEmptyResult.title")}
    </Alert>
  )
}

export default OrderTableEmptyResult
